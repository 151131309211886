//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from "vuedraggable";
import {ValueType} from "../ComponentsConfigExport";
import FormDesignRender from '@/page/admin/approval-process/component/form/FormDesignRender'
import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "TableList",
  components: {draggable, FormDesignRender},
  props: {
    value:{
      type: Array,
      default: () => {
        return []
      }
    },
    placeholder: {
      type: String,
      default: '添加数据'
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    maxSize: {
      type: Number,
      default: 0
    },
    rowLayout: {
      type: Boolean,
      default: true
    },
  },
  created() {
    if (!Array.isArray(this.value)){
      this._value = []
    }
  },
  computed: {
    rules(){
      const rules = {}
      this.columns.forEach(col => {
        if (col.props.required){
          rules[col.id] = [{
            type: col.valueType === 'Array' ? 'array':undefined,
            required: true,
            message: `请填写${col.title}`, trigger: 'blur'
          }]
        }
      })
      return rules
    },
    _columns: {
      get() {
        return this.columns;
      },
      set(val) {
        this.columns = val;
      }
    },
    selectFormItem: {
      get() {
        return this.$store.state.selectFormItem
      },
      set(val) {
        this.$store.state.selectFormItem = val
      },
    }
  },
  data() {
    return {
      select: null,
      drag: false,
      ValueType
    }
  },
  methods: {
    getMinWidth(col){
      switch (col.name){
        case 'DateTime': return '250px'
        case 'DateTimeRange': return '280px'
        case 'MultipleSelect': return '200px'
        default: return '150px'
      }
    },
    showError(col, val){
      if (col.props.required){
        switch (col.valueType){
          case ValueType.dept:
          case ValueType.user:
          case ValueType.dateRange:
          case ValueType.array: return !(Array.isArray(val) && val.length > 0)
          default: return !this.$isNotEmpty(val)
        }
      }
      return false
    },
    copyData(i, row){
      this._value.push(this.$deepCopy(row))
    },
    delRow(i, row){
      this._value.splice(i, 1)
    },
    addRow(){
      if (this.maxSize > 0 && this._value.length >= this.maxSize){
        this.$message.warning(`最多只能添加${this.maxSize}行`)
      }else {
        let row = {}
        this.columns.forEach(col => this.$set(row, col.id, undefined))
        this._value.push(row)
        this.$set(this, '_value', this._value)
      }
    },
    delItem(id) {
      this._columns.splice(id, 1)
    },
    selectItem(cp) {
      this.selectFormItem = cp
    },
    getSelectedClass(cp) {
      return this.selectFormItem && this.selectFormItem.id === cp.id ? 'border-left: 4px solid #f56c6c' : ''
    },
    validate(call){
      if (this.rowLayout){
        let result = true
        for (let i = 0; i < this.columns.length; i++) {
          if (this.columns[i].props.required){
            for (let j = 0; j < this._value.length; j++) {
              result = !this.showError(this.columns[i], this._value[j][this.columns[i].id])
              if (!result){
                call(false)
                return
              }
            }
          }
        }
        call(result)
      }else {
        let success = 0
        this._value.forEach((v, i) => {
          let formRef = this.$refs[`table-form-${i}`]
          if (formRef && Array.isArray(formRef) && formRef.length > 0){
            formRef[0].validate(valid => {
              if (valid){
                success ++;
              }
            })
          }
        })
        if (success === this._value.length){
          call(true)
        }else {
          call(false)
        }
      }
    }
  }
}
